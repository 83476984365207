<template>
  <div class="w-full sm:w-1/4 lg:w-1/5" style="min-width:200px">
    <div class="ml-2 mt-4 mr-4 sm:ml-0 sm:mr-3 sm:my-8 bg-white shadow-md rounded-lg h-auto bg-primary-600">
      <!-- Название терапевтической области -->
      <h2 
        v-on:click="showNosology = !showNosology"
        class="flex text-xl justify-center px-4 py-3 
              border-2 rounded-lg border-primary-500 bg-gray-200 text-primary-600 
              sm:block sm:text-white sm:font-semibold sm:bg-primary-500"
      >
        {{ therapyAreaName }}
        <font-awesome-icon 
          :class="[{'block': !showNosology, 'hidden': showNosology}]"
          class="ml-auto sm:hidden" 
          icon="sort-down"
        />
        <font-awesome-icon 
          :class="[{'block': showNosology, 'hidden': !showNosology}]"
          class="ml-auto sm:hidden mt-auto" 
          icon="sort-up"
        />
      </h2>

      <!-- Выпадающий список -->
      <div 
        :class="[{'block': showNosology, 'hidden': !showNosology}]"
        class="sm:block">
        <ThemesListItem
          v-for="nosology in filteredNosologies"
          :key="nosology.id"
          :id="nosology.id"
          :title="nosology.name"
          :themes="nosology.themes"
          :isSelected="activeNosology == nosology.id"
          @list-click="nosologyClick($event)"
          v-on:sublist-click="mobileThemesClose"
        />
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ThemesListItem from '@/components/therapyarea/ThemesListItem.vue'

export default {
  name: 'ThemesList',
  components: {
    ThemesListItem
  },
  data: function() {
    return {
      showNosology: false,
      activeNosology: null
    }
  },
  computed: {
    ...mapState({
      'therapyAreaName': state => state.therapyArea.name,
      'nosologies': state => state.nosologies,
      'currentNosologyId': state => state.currentNosologyId
    }),
    filteredNosologies() {
      if (this.$route.name === 'Videos' || this.$route.name === 'Video') {
        return this.nosologies.filter((nosology) =>
          nosology.themes.some((theme) => theme.videosCount !== 0))
      }
      if (this.$route.name === 'Tests' || this.$route.name === 'Test') {
        return this.nosologies.filter((nosology) =>
          nosology.themes.some((theme) => theme.testsCount !== 0))
      }
      if (this.$route.name === 'ClinicalDatas' || this.$route.name === 'ClinicalData')
        return this.nosologies.filter(nosology => nosology.datasCount !== 0)
    }
  },
  methods: {
    nosologyClick({id, title}) {
      if (this.activeNosology == id) {
        this.activeNosology = null
        if (this.$route.name === 'ClinicalDatas' || this.$route.name === 'ClinicalData') {
          this.$store.dispatch('setToIndex')
        }
      } else {
        this.$store.dispatch('setCurrentNosology', {id, title})
        this.activeNosology = id
      }

      if (this.$route.name === 'ClinicalData')
        this.$router.push({ name: 'ClinicalDatas' })
    },
    mobileThemesClose() {
      this.showNosology = false
    }
  }
}
</script>
