<template>
  <div>
    <div
      :class="[{'bg-white text-gray-700': isSelected, 'text-gray-200 hover:text-white hover:bg-primary-500': !isSelected}]"
      class="flex block items-center cursor-pointer py-3 px-3 border-b border-white text-left text-sm"
      @click="$emit('list-click', {id, title})"
    >
      <span class="text-md" v-if="isSelected"><font-awesome-icon icon="minus"/></span>
      <span class="text-md" v-if="!isSelected"><font-awesome-icon icon="plus"/></span>
      <span class="pl-3">{{ title }}</span>
    </div>

    <collapse-transition :duration="300">
      <div v-if="isSelected">
        <ThemesSublistItem
          v-for="theme in filteredThemes"
          :key="theme.id"
          :id="theme.id"
          :title="theme.name"
          @click="$emit('sublist-click')"
        />
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ThemesSublistItem from '@/components/therapyarea/ThemesSublistItem.vue'
import { CollapseTransition } from 'vue2-transitions'

export default {
  name: 'ThemesListItem',
  components: {
    ThemesSublistItem,
    CollapseTransition
  },
  props: {
    id: Number,
    title: String,
    themes: Array,
    isSelected: Boolean
  },
  computed: {
    ...mapState([
      'currentThemeId'
    ]),
    filteredThemes() {
      if (this.$route.name === 'Videos' || this.$route.name === 'Video') {
        return this.themes.filter(theme => theme.videosCount !== 0)
      }
      if (this.$route.name === 'Tests' || this.$route.name === 'Test') {
        return this.themes.filter(theme => theme.testsCount !== 0)
      }
    }
  }
}
</script>

<style>
</style>
