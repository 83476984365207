<template>
  <div>    
    <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

      <!-- Loading screen -->
      <div v-if="isLoading" key="animation">
        <LoadingScreen />
      </div>

      <!-- TherapyArea page -->
      <div
        v-if="!isLoading"
        class="flex flex-wrap sm:flex-no-wrap mx-auto pl-2 sm:pl-6 lg:pl-8"
        key="content"
      >

        <!-- Left navigation menu -->
        <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">
          <ThemesList v-if="hadThemes" />
        </fade-transition>

        <div class="w-full mx-auto md:w-4/5 px-2 md:px-6">

          <!-- Content header -->
          <ContentHeader />

          <!-- Main content -->
          <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">
            <router-view name="content" />
          </fade-transition>
        </div>

      </div>
    </fade-transition>
    <Footer /> 
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'

import ThemesList from '@/components/therapyarea/ThemesList.vue'
import ContentHeader from '@/components/therapyarea/ContentHeader.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'TherapyArea',
  components: {
    ThemesList,
    ContentHeader,
    Footer,
    LoadingScreen,
    FadeTransition
  },
  data: function () {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapState([
      'therapyArea',
      'nosologies',
      'currentThemeTitle'
    ]),
    hadThemes() {
      if (this.$route.name == 'Experts') return false
      if (this.$route.name == 'Partners') return false
      if (this.$route.name == 'Contacts') return false
      if (this.$route.name == 'Search') return false
      if (this.$route.name == 'ClinicalCases') return false
      if (this.$route.name == 'ClinicalCase') return false
      if (this.$route.name == 'Redcollege') return false
      return true
    },
  },
  methods: {
    ...mapActions([
      'getTherapyArea', 'resetTherapyArea'
    ])
  },
  created: function() {
    // Загрузка данных тер. области
    this.getTherapyArea(this.$route.params.therapyarea_url)
        .then(() => {
          this.isLoading = false
        })
        .catch(() => {
          this.$router.replace({ name: 'Not found' })
        })
  },
  beforeDestroy: function() {
    // Очистка состояния при выходе
    this.resetTherapyArea()
  }
}
</script>

<style>
</style>