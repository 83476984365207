<template>
    <div
      :class="[{'bg-gray-200': isSelected, 'hover:bg-gray-200':!isSelected}]"
      class="flex block bg-white text-gray-700 items-center cursor-pointer py-2 pl-6 pr-3 border-b
            border-gray-300 text-left text-sm"
      @click="click()"
    >
      <span class="text-lg" v-if="isSelected"><font-awesome-icon icon="caret-right"/></span>
      <span class="text-lg text-white" v-if="!isSelected"><font-awesome-icon icon="caret-right"/></span>
      <span class="pl-2">{{ title }}</span>
    </div>
</template>

<script>
export default {
  name: 'ThemesSublistItem',
  props: {
    id: Number,
    title: String
  },
  computed: {
    isSelected() {
      return this.$store.state.currentThemeId ? 
        this.$store.state.currentThemeId == this.id : this.$store.state.contentThemeid == this.id
    }
  },
  methods: {
    click() {
      this.$store.dispatch('setCurrentTheme', this.id)
      if (!this.$store.getters.isItListRoute) {
        this.$router.push(this.$store.getters.getListRoute)
      }
    }
  }
}
</script>

<style>
</style>
